<template>
  <div class="home" v-loading="loading">
    <!-- 导航按钮 -->
    <div class="back-top"></div>
    <!-- 顶部大图 -->
    <div class="slogan slide-one" id="home">
      <div>基于量子安全的智能云服务平台</div>
      <div class="home-des home-title">
        Intelligent Cloud Service Platform Based <br />
        on Quantum Security
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="content" id="abous">
      <Headline title="关于我们" subtitle="ABOUT US"></Headline>
      <div class="summary">
        <h5>江苏微知量子科技有限公司</h5>
        <span class="content">
          致力成为量子信息产业生态链中的一环，为政府、企业提供量子信息技术综合解决方案。公司在网络的量子安全升级、量子安全的密码云/数据云/算力云，以及融合量子计算与经典计算的战略混合集群等领域，能够提供整体解决方案和相应的硬件设备与软件平台、关键设备与产品，公司被列入了“无锡市量子科技企业图谱”，为政府、企业的数据全生命周期安全保驾护航。
        </span>
      </div>
    </div>
    <!-- 产品 -->
    <div class="product_sty" id="product">
      <Headline title="产品" subtitle="PRODUCT"></Headline>
      <div class="product">
        <div class="product_con">
          <div class="product_one">
            <h2>研发量子安全的数据采集和存储产品,建设量子安全数据云</h2>
            <div class="left_p">
              <p>根据业务动态调度算力，提升运营效率</p>
              <p>协同边缘节点，提升实时性和降低网络负荷</p>
              <p>分布式计算中心，降低单一节点能耗</p>
              <p>灵活部署加速板卡，提升AI算力</p>
              <p>基于AI加速卡 + QSS加密的高性能服务器</p>
            </div>
          </div>
          <div class="top_bottom">
            <div class="con_one">
              <h2>网络的量子安全升级</h2>
              <div class="div_p">
                <p>光纤网络升级</p>
                <p>量子安全虚拟专网升级</p>
                <p>量子安全移动服务</p>
              </div>
            </div>
            <div class="con_two">
              <h2>构建量子密码云</h2>
              <div class="div_p">
                <p>市级密钥管理服务平台组网</p>
              </div>
            </div>
            <div class="con_three">
              <h2>高性能智能计算技术 构建量子安全算力云</h2>
              <div class="div_p">
                <p>生物医药企业、智算中心、科研机构等 拷贝</p>
              </div>
            </div>
            <div class="con_four">
              <h2>量子-经典混合集群方案建设</h2>
              <div class="div_p">
                <p>生物医药企业、智算中心、科研机构等</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务 -->
    <div class="service_sty" id="service">
      <Headline title="服务" subtitle="SERVICE"></Headline>
      <div class="service">
        <div class="frame">
          <div class="frame_top">
            <h4>01</h4>
            <p>为政府、企业提供量子安全密码服务</p>
          </div>
          <div class="frame_bottom">
            建设量子密钥分发网络、低成本升级现有线路为量子安全等级；为用户提供量子密码服务，助力用户信息系统达到等保、密评的要求。
          </div>
        </div>

        <div class="frame">
          <div class="frame_top">
            <h4>02</h4>
            <p>为企业、计算中心提供高安全云部署方案、 建设及运维服务</p>
          </div>
          <div class="frame_bottom">
            结合量子安全与高效节能的特点，为客户打造绿色环保安全的公有云/私有云。
          </div>
        </div>

        <div class="frame">
          <div class="frame_top">
            <h4>03</h4>
            <p>为客户提供量子安全高性能计算服务</p>
          </div>
          <div class="frame_bottom">
            提供每秒运算亿亿级的融合量子安全的高性能智能算力公共服务平台。
          </div>
        </div>

        <div class="frame">
          <div class="frame_top">
            <h4>04</h4>
            <p>为客户提供量子安全的分布式云存储服务</p>
          </div>
          <div class="frame_bottom">
            量子安全与分布式存储技术，确保用户数据的私密性、安全性；打消用户数据上云顾虑。
          </div>
        </div>

        <div class="frame">
          <div class="frame_top">
            <h4>05</h4>
            <p>与量子计算机结合形成混合型计算集群</p>
          </div>
          <div class="frame_bottom">
            参与长三角量子科技产业创新中心等相关工作，提供混合型计算集群平台和经典算力服务,为政府和企业提供精细化管理咨询服务。
          </div>
        </div>

        <div class="frame">
          <div class="frame_top">
            <h4>06</h4>
            <p>为政府和企业提供精细化管理咨询服务</p>
          </div>
          <div class="frame_bottom">
            结合量子安全与高效节能的特点，为客户打造绿色环保安全的公有云/私有云。
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="content-partner" id="partner">
      <div class="partner">
        <Headline title="合作伙伴" subtitle="RARTNERS"></Headline>
        <div class="img_sty">
          <el-row class="img_row">
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz1.png" alt style="width: 150px" />
            </el-col>
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz2.png" alt style="width: 150px" />
            </el-col>
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz3.png" alt style="width: 150px" />
            </el-col>
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz4.png" alt style="width: 150px" />
            </el-col>
          </el-row>
          <el-row class="img_row">
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz5.png" alt style="width: 150px" />
            </el-col>
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz6.png" alt style="width: 150px" />
            </el-col>
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz7.png" alt style="width: 150px" />
            </el-col>
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz9.png" alt style="width: 150px" />
            </el-col>
          </el-row>
          <el-row class="img_row">
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz10.png" alt style="width: 150px" />
            </el-col>
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz11.png" alt style="width: 150px" />
            </el-col>
            <el-col :span="12" class="img_col">
              <img src="@/assets/images/hz12.png" alt style="width: 150px" />
            </el-col>
            <el-col :span="12">
              <!-- <img src="@/assets/images/hz8.png" alt style="width: 150px" /> -->
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "Home_index",
  components: {
    Headline: () => import("../components/headline"),
    swiper,
    swiperSlide,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    maodian() {
      return this.$store.state.page.maodian;
    },
  },
  watch: {
    maodian(newData, oldData) {
      if (newData == "/case") {
        this.$router.push(newData);
        localStorage.setItem("locationUrl", newData);
      } else {
        document.querySelector(newData).scrollIntoView(true);
        localStorage.setItem("locationUrl", "/h5");
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home {
  margin-top: 230px;
}
.slide-one {
  background: url(../assets/images/index2.png) no-repeat center;
  background-size: cover;
}
.slide-two {
}
.slide-three {
}
.slide-four {
}
.slide-five {
  background-color: #eff4ff;
}

/*top页*/
.slogan {
  height: 1240px;
  font-size: 86px;
  font-weight: 400;
  font-family: Source Han Sans CN;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12vw;
  .home-title {
    color: #615bfd;
  }
  .home-des {
    font-size: 36px;
    margin-top: 61px;
  }
}

// 关于我们
.content {
  width: 1400px;
  margin: 0 auto;
  padding: 310px 0;
  .summary {
    margin: 0 auto;
    margin-top: 200px;
    font-family: Source Han Sans CN;
    h5 {
      font-size: 48px;
      color: #3471f3;
      line-height: 60px;
      display: contents;
    }
    .content {
      font-size: 25px;
      color: #333333;
      line-height: 60px;
    }
  }
}

//产品
.product_sty {
  padding-top: 100px;
  // background-color: #eff4ff;
  .product {
    margin-top: 200px;
    display: flex;
    justify-content: center;
    .product_con {
      width: 90%;
      .product_one {
        width: 1400px;
        margin: 0 auto;
        padding: 20px;
        font-family: Source Han Sans CN;
        background: url(../assets/images/chan_pin1.png) no-repeat center;
        background-size: cover;
        border-radius: 5px;
        h2 {
          height: 150px;
          line-height: 80px;
          font-size: 28px;
          font-weight: bold;
          color: #333333;
          margin: 24px;
        }
        .left_p {
          height: 550px;
          line-height: 76px;
          font-size: 18px;
          font-weight: 400;
          color: #666666;
          margin: 0 24px;
          padding-top: 80px;
        }
      }
      .top_bottom {
        width: 1400px;
        margin: 0px auto;
        .con_one,
        .con_two,
        .con_three,
        .con_four {
          width: 1400px;
          height: 600px;
          border-radius: 10px;
          margin: 100px 0;
          h2 {
            height: 150px;
            line-height: 150px;
            font-size: 28px;
            font-weight: bold;
            color: #333333;
            margin: 24px;
          }
          .div_p {
            height: 150px;
            line-height: 100px;
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin: 0 24px;
          }
        }
        .con_one {
          margin-right: 18px;
          background: url(../assets/images/chan_pin2.png) no-repeat center;
          background-size: cover;
        }
        .con_two {
          background: url(../assets/images/chan_pin3.png) no-repeat center;
          background-size: cover;
        }
        .con_three {
          margin-right: 18px;
          background: url(../assets/images/chan_pin4.png) no-repeat center;
          background-size: cover;
        }
        .con_four {
          background: url(../assets/images/chan_pin5.png) no-repeat center;
          background-size: cover;
        }
      }
    }
  }
}

// 服务
.service_sty {
  padding-top: 100px;
  .service {
    width: 1400px;
    margin: 0 auto;
    padding: 200px 0;
    .frame {
      width: 100%;
      margin-bottom: 100px;
      box-shadow: 0 0 10px #7d7d7d;
      // background: url(../assets/images/fuwu1.png) no-repeat center;
      // background-size: cover;
      .frame_top {
        height: 200px;
        display: flex;
        background: #3370f3;
        position: relative;
        h4 {
          width: 60px;
          height: 200px;
          line-height: 200px;
          font-size: 40px;
          font-family: PangMenZhengDao;
          font-weight: 400;
          color: #eff4ff;
          opacity: 0.45;
          padding-left: 10px;
        }
        p {
          flex: 1;
          font-size: 22px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #eff4ff;
          padding-right: 10px;
          position: absolute;
          top: 50%;
          left: 100px;
          transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-tranform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
        }
      }
      .frame_bottom {
        line-height: 100px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #7d7d7d;
        padding: 45px;
      }
    }
  }
}

//合作伙伴
.content-partner {
  background-color: #eff4ff;
  padding: 200px 0;
  display: flex;
  align-items: center;
  .partner {
    width: 1800px;
    margin: 0 auto;
    .img_sty {
      margin-top: 200px;
      .img_row {
        .img_col {
          height: 300px;
          line-height: 70px;
          text-align: center;
          padding: 20px;
          // margin: 15px;
          background: url(../assets/images/white.png) no-repeat center;
          background-size: cover;
        }
      }
      img {
        height: 250px;
      }
    }
  }
}

.order {
  order: -1;
}
.order-img {
  order: 1;
}

// 底部栏
.footersty {
  width: 100%;
  height: 216px;
  overflow: hidden;
  background-color: #14679f;
  &-content {
    width: 1240px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    .content-nav {
      display: flex;
      justify-content: space-around;
      li {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        //justify-content: center;
        align-items: flex-start;
        p {
          font-size: 20px;
          color: #d4edff;
          padding: 10px 0;
        }
        .pointer {
          cursor: pointer;
        }
        span {
          color: #f7f7f7;
          font-weight: 300;
          padding: 5px 0;
        }
      }
    }
    .logo {
      img {
        width: 171px;
        height: 53px;
        padding: 10px;
      }
      p {
        margin-left: 10px;
        color: #d4edff;
        span {
          color: #f7f7f7;
        }
      }
    }
  }
}
.default {
  cursor: default;
}
</style>
